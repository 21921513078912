import * as Yup from "yup";

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const SignupValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),

  last_name: Yup.string().required("Last Name is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

    // phone_no: Yup.string()
    // .matches(/^\d{10}$/, 'Invalid phone number') // Assuming 10 digits phone number
    // .required('Phone Number is required'),

  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const ForgotValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required("Current password is required"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Please confirm your new password"),
});

export const CartCheckout = Yup.object().shape({
  address: Yup.string().required("Address is required"),
  order_type: Yup.string().required("Order type is required"),
  // longitude: Yup.string().required("Longitude  is required"),
  // latitude: Yup.string().required("Latitude  is required"),
  promo_code: Yup.string(),
});
export const ConfirmPasswordReset = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),

  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password_confirmation"), null], "Passwords must match")
    .required("Confirm Password is required"),
});