import React, { useEffect, useRef, useState } from "react";
// import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/slice/userSlice";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { user } = useSelector((user) => user.user);
  console.log("USER:", user);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [value, setValue] = useState("MUZAMMIL");

  const { t } = useTranslation();

  const fileInputRef = useRef(null);
  //   console.log("USER:", user);
  // const [value, setValue] = useState();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    address: "" || user?.address,
    latitude: "",
    longitude: "",
    first_name: "" || user?.first_name,
    last_name: "" || user?.last_name,
    phone_no: "" || user?.phone_no,
    image: "" || user?.image,
  });

  const handleInputChange = (e) => {
    console.log("onchange work", e);
    const { name, value, files } = e.target;

    if (files) {
      console.log("IF FILES");
      setImagePreview(URL.createObjectURL(files[0]));
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handlePhoneChange = (value) => {
    console.log("valuevaluevaluevaluePhoen", value);
    // setValue(value);
    setFormData({ ...formData, phone_no: value });
  };

  const triggerFileInput = () => {
    console.log("CLICK ON IMG");
    fileInputRef.current.click();
  };
  const { mutate, isPending } = useMutation({
    mutationFn: apis.updateProfile,
    onError: function ({ message }) {
      console.log("MESSAGE ERROR:", message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      console.log("data:", data);
      dispatch(setUser(data?.data));
      toast.success(t("responses.toast.profileUpdate"));
    },
  });

  const handleSubmit = () => {
    console.log("FORM DATA", formData);
    // if (formData.first_name.length < 2 || formData.last_name.length > 50) {
    //   toast.error("Name must be between 2 and 50 characters", { id: 1 });
    //   return;
    // }

    const form_data = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      form_data.append(key, value);
    }
    console.log("FORMDATAAA", formData);
    mutate(form_data);
  };

  useEffect(() => {
    if (selectedPlace) {
      handleAddress(selectedPlace);
    }
  }, [selectedPlace]);
  console.log("selectedPlace", selectedPlace);
  const handlePlaceSelect = async (place) => {
    console.log("FUNCTION RUN,", place);
    setSelectedPlace(place);

    try {
      const results = await getLatLng(place);
      console.log("Latitude:", results.lat);
      console.log("Longitude:", results.lng);
    } catch (error) {
      console.error("Error fetching LatLng:", error);
    }
  };
  const handleAddress = ({ label }) => {
    console.log("LABEL", label);
    geocodeByAddress(label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setFormData((prevState) => ({
          ...prevState,
          latitude: lat.toString(),
          longitude: lng.toString(),
          address: label,
        }));
      })
      .catch((error) => console.error(error));
  };
  console.log("FORM DATA:", formData);

  return (
    <section className="container-fluid" id="jtc-token">
      <div className="heading-bg">
        <h2>{t("titles.profile")}</h2>
      </div>
      <div id="profile">
        <div className="img-con">
          <img src="/assets/images/profile-frame.png" alt="" width="100%" />
          <div className="profile-wrapper">
            <div className="btn-wrapper">
              <img src={imagePreview || user?.image} alt="" />
              <input
                type="file"
                className="d-none"
                ref={fileInputRef}
                name="image"
                onChange={handleInputChange}
              />
              <svg
                className="edit"
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={triggerFileInput}
              >
                <rect width="54" height="54" rx="9.6" fill="#E0201C" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.2009 35.5631L33.5731 23.4432C34.0824 22.7896 34.2635 22.0339 34.0937 21.2645C33.9466 20.5651 33.5165 19.9 32.8713 19.3955L31.2979 18.1457C29.9283 17.0564 28.2305 17.1711 27.257 18.4209L26.2044 19.7865C26.0685 19.9574 26.1025 20.2096 26.2723 20.3472C26.2723 20.3472 28.9323 22.48 28.9889 22.5259C29.17 22.6978 29.3058 22.9272 29.3397 23.2024C29.3963 23.7413 29.0228 24.2458 28.4682 24.3146C28.2078 24.349 27.9588 24.2687 27.7777 24.1197L24.9819 21.8952C24.8461 21.7931 24.6424 21.8149 24.5292 21.9525L17.8849 30.5523C17.4548 31.0912 17.3076 31.7907 17.4548 32.4672L18.3037 36.1479C18.349 36.3428 18.5188 36.4804 18.7225 36.4804L22.4578 36.4346C23.1369 36.4231 23.7708 36.1135 24.2009 35.5631ZM29.4319 34.4166H35.5226C36.1169 34.4166 36.6002 34.9062 36.6002 35.5082C36.6002 36.1114 36.1169 36.5998 35.5226 36.5998H29.4319C28.8376 36.5998 28.3543 36.1114 28.3543 35.5082C28.3543 34.9062 28.8376 34.4166 29.4319 34.4166Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="input-parent">
          <div className="one">
            <label for="">{t("labels.name")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                name="first_name"
                id=""
                placeholder={t("placeholders.firstName")}
                defaultValue={user?.first_name}
                onChange={handleInputChange}
              />
              <img src="/assets/images/form-icon/profile-name.png" alt="" />
            </div>
          </div>
          <div className="one">
            <label for="">{t("labels.lastName")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                name="last_name"
                id=""
                placeholder={t("placeholders.lastName")}
                defaultValue={user.last_name}
                onChange={handleInputChange}
              />
              <img src="/assets/images/form-icon/profile-name.png" alt="" />
            </div>
          </div>
          <div className="one" style={{ display: "" }}>
            <label for="">{t("labels.email")}</label>
            <div className="input-wrapper">
              <input
                type="email"
                name=""
                id=""
                value={user?.email}
                placeholder={t("placeholders.email")}
              />
              <img src="/assets/images/form-icon/pofile-email.png" alt="" />
            </div>
          </div>
          <div className="one">
            <label for="">{t("labels.phoneNo")}</label>
            <div className="input-wrapper">
              {/* <input type="tel" name="" id="phon" placeholder="+91 000 0000000"
                        value=""/> */}
              {/* <PhoneInput
                placeholder="Enter phone number"
                value={formData.phone_no}
                name="phone_no"
                onChange={handlePhoneChange}
                style={{ display: "flex" }}
              /> */}
              <PhoneInput
                country="mx"
                value={user?.phone_no}
                inputProps={{ name: "phone" }}
                inputStyle={{ width: "100%" }}
                name="phone_no"
                onChange={handlePhoneChange}
                required
              />
              {/* <img
                src="/assets/images/form-icon/profile-flag.png"
                alt=""
                width="20px"
              /> */}
            </div>
          </div>
          <div className="one">
            <label for="">{t("labels.address")}</label>
            <div className="input-wrapper">
              {/* <input
                type="text"
                name="address"
                id=""
                placeholder="Block 1, Gulshan e iqbal, ka..."
                value={formData.address}
                onChange={handleInputChange}
              /> */}
              {console.log("userAddress", selectedPlace)}
              <GooglePlacesAutocomplete
                apiKey="AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk"
                selectProps={{
                  selectedPlace,
                  onChange: setSelectedPlace,
                  onSelect: handlePlaceSelect,
                  placeholder: t("placeholders.address"),

                  // defaultValue: "TEST",
                  defaultValue: { label: user?.address, value: "" },
                }}
              />
              <img
                src="/assets/images/form-icon/profile-location.png"
                alt=""
                className="img-address-profile"
              />
            </div>
          </div>
          <div className="one">
            <label for="">{t("labels.nearestAddress")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                name="nearest_address"
                id=""
                // placeholder={user?.first_name}
                placeholder={t("placeholders.nearestAddress")}
                // defaultValue={"Nearest Address"}
                onChange={handleInputChange}
              />

              <img
                src="/assets/images/form-icon/profile-location.png"
                alt=""
                className="img-address-profile"
              />
            </div>
          </div>
        </div>
        <div className="btn-con">
          <Link to="/change-password">
            {" "}
            <button>{t("buttons.changePassword")}</button>
          </Link>{" "}
          <button
            onClick={handleSubmit}
            //   disabled={isPending}
          >
            {isPending ? t("buttons.saving") : t("buttons.save")}
            {/* SAVE */}
          </button>{" "}
        </div>
      </div>
    </section>
  );
};

export default Profile;
