import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import VerificationInput from "react-verification-input";
import apis from "../../services";
// import { OtpSuccessModal } from "./OtpSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import useNavigate from "../../helper/useNavigate";

import { setUser } from "../../redux/slice/userSlice";
import { ImCross } from "react-icons/im";
import detectLang from "../../helper/detectLanguage";
import { useTranslation } from "react-i18next";

export const VerifyOTPModal = ({
  showVerifyOTP,
  setShowVerifyOTP,
  setShowOTPSuccess,
  email,
}) => {
  const [otp, setOtp] = useState("");
  const location = useLocation().pathname;
  let { user } = useSelector((store) => store.user);
    console.log("emailemail", email);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { mutate, isPending } = useMutation({
    mutationFn: apis.authVerify,
    onError: function ({ message }) {
      console.log("message:", message);
      toast.error(message);
    },
    onSuccess: ({ data: userOtp, status }) => {
      console.log("userOtp:", userOtp);
      if (userOtp?.status) {
        console.log("inside 200");
        setShowVerifyOTP(false);

        if (location.includes("/forgot-password")) {
          navigate(`/confirm-password?email=${userOtp?.data?.email}`);
        } else {
          setShowOTPSuccess(true);
          dispatch(setUser(userOtp?.data));

          localStorage.setItem("token", userOtp?.token);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }

        // toast.success(message);
      } else {
      }
    },
  });
  const isForgotPasswordRoute = location === "/en/forgot-password" || "/sp/forgot-password";
  console.log("isForgotPasswordRoute", isForgotPasswordRoute);
  //RESEND OTP
  const { mutate: mutateResend, isPending: isLoading } = useMutation({
    mutationFn: apis.authResend,
    onError: function ({ message }) {
      console.log("message:", message);
      toast.error(message);
    },
    onSuccess: ({ data: resendOtp, status }) => {
      console.log("resendOtp:", resendOtp);
      if (resendOtp?.status) {
        toast.success(t('responses.toast.otpResend'), { id: 1 });

        localStorage.setItem("token", resendOtp?.token);
        // console.log("inside 200");

        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
        // toast.success(message);
      } else {
      }
    },
  });

  //RESEND OTP FORGOT
  const { mutate: mutateResendForgot, isPending: isLoadingForgot } =
    useMutation({
      mutationFn: apis.authResendForgot,
      onError: function ({ message }) {
        console.log("message:", message);
        toast.error(message);
      },
      onSuccess: ({ data: resendOtp, status }) => {
        console.log("resendOtp:", resendOtp);
        if (resendOtp?.status) {
          toast.success(t('responses.toast.otpResend'), { id: 1 });
          localStorage.setItem("token", resendOtp?.token);
          // console.log("inside 200");

          // setTimeout(() => {
          //   navigate("/");
          // }, 2000);
          // toast.success(message);
        } else {
        }
      },
    });

  const handleResend = () => {
    // console.log("user email", user.email);
    if (!isLoading) {
      if (isForgotPasswordRoute) {
        mutateResendForgot({
          email: email,
          type: 1,
        });
      } else {
        mutateResend({
          email: user?.email,
          type: 1,
        });
      }
    }
  };

  const getOtp = (value) => {
    setOtp(value);
  };
  const handleSubmit = () => {
    console.log("OTP:", otp);
    if (!otp || otp.length !== 5) {
      toast.error("Please enter a valid OTP.");
      return;
    }
    mutate({
      email: email ? email : user?.email,
      code: Number(otp),
      type: 1,
    });
  };
  return (
    <>
      <Modal
        show={showVerifyOTP}
        onHide={() => setShowVerifyOTP(false)}
        centered
        size="lg"
        className="verify-otp-modal"
        // backdrop="static"
        // keyboard={false}
      >
        <div className="modal-content">
          <div
            className="cross-sign-container"
            onClick={() => setShowVerifyOTP(false)}
          >
            <ImCross />
          </div>
          <div className="modal-body" id="" bis_skin_checked={1}>
            <div className="up" bis_skin_checked={1}>
            <h3>{t("titles.emailverify")}</h3>
            <p>{t("messages.otpSendMessage")}</p>
            </div>
            <div className="down" bis_skin_checked={1}>
              <div className="one">
                <p>{email}</p>
                {/* <div class="edit-con">
                                <img src="/assets/images/edit-otp.png" alt="edit-otp" />
                                <p>Edit</p>
                            </div> */}
              </div>
              <div className="two" bis_skin_checked={1}>
                <VerificationInput
                  length={5}
                  placeholder="_"
                  classNames={{
                    container: "container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                  }}
                  value={otp}
                  onChange={getOtp}
                />
              </div>
              <div className="three" bis_skin_checked={1}>
                <p>
                {t("descriptions.didNotRecieveCode")}
                  <span id="resend-otp-btn" onClick={handleResend}>
                    {" "}
                    {t("buttons.reSend")}
                  </span>
                </p>
                <button
                  type="submit"
                  className=""
                  id="otp-Modal"
                  onClick={handleSubmit}
                  disabled={isPending}
                >
                 {isPending
                    ? t("buttons.verifying")
                    : t("buttons.verifyAccount")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
