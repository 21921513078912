import React, { useEffect, useState } from "react";
import Testimonial from "./Testimonial";
import BannerSection from "./BannerSection";
import { ShopCard } from "../components";
import { useDispatch, useSelector } from "react-redux";
import apis from "../services";
import { LocationModal } from "../components/Modals";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const HomeScreen = () => {
  const [showLocSuccess, setShowLocSuccess] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const dispatch = useDispatch();
  let { product } = useSelector((product) => product.product);
  console.log("PRODUCYT", product);
  let { user } = useSelector((store) => store.user);
  console.log("user", user);
  // useEffect(() => {
  //   if (!user?.lat && !user?.long) {
  //     setShowLocSuccess(true);
  //   }
  // }, [user]);

  // const { mutate, isPending } = useMutation({
  //   mutationFn: apis.updateProfile,
  //   onError: function ({ message }) {
  //     console.log("MESSAGE ERROR:", message);
  //     toast.error(message, { id: 1 });
  //   },
  //   onSuccess: ({ data, status }) => {
  //     setShowLocSuccess(false);
  //     console.log("data:", data);
  //     dispatch(setUser(data?.data));
  //     // toast.success("Profile updated successfully");
  //   },
  // });

  // const handleClick = () => {
  //   console.log("CLICK");

  //   if (user) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         console.log("latitude", latitude);
  //         axios
  //           .get(
  //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDLSQ4yAL3LqVTuqVZWUMshrNTGoaDtcII`
  //           )
  //           .then((response) => {
  //             console.log("RESPONSE", response);
  //             const formattedAddress =
  //               response.data.results[0].formatted_address;
  //             // console.log("formattedAddress", formattedAddress);

  //             mutate({
  //               lat: latitude,
  //               long: longitude,
  //               address: formattedAddress,
  //             });
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching address:", error);
  //           });
  //       },
  //       (error) => {
  //         console.error("Error getting user location:", error);
  //       },
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //       }
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (selectedPlace) {
  //     handleAddress(selectedPlace);
  //   }
  // }, [selectedPlace]);

  // const handlePlaceSelect = async (place) => {
  //   console.log("FUNCTION RUN,", place);
  //   setSelectedPlace(place);

  //   try {
  //     const results = await getLatLng(place);
  //     console.log("Latitude:", results.lat);
  //     console.log("Longitude:", results.lng);
  //   } catch (error) {
  //     console.error("Error fetching LatLng:", error);
  //   }
  // };
  // const handleAddress = ({ label }) => {
  //   geocodeByAddress(label)
  //     .then((results) => getLatLng(results[0]))
  //     .then(({ lat, lng }) => {
  //       mutate({
  //         lat: lat,
  //         long: lng,
  //         address: selectedPlace.label,
  //       });
  //       // setDataProceed((prevState) => ({
  //       //   ...prevState,
  //       //   latitude: lat.toString(),
  //       //   longitude: lng.toString(),
  //       //   address: user.address,
  //       // }));
  //     })
  //     .catch((error) => console.error(error));
  // };

  // // console.log("product", product);
  // //   const [data, setData] = useState();
  // //   const fetchData = async () => {
  // //     try {
  // //       const result = await apis.getAllProductWithCategory();
  // //       setData(result?.data?.data[0]?.shop_product);
  // //       console.log(result?.data?.data[0]?.shop_product,"result?.data?.data[0]?.shop_product")
  // //     } catch (error) {
  // //       console.error("Error fetching data:", error);
  // //     }
  // //   };

  // // useEffect(() => {
  // //     fetchData();
  // //   }, []);

  // // console.log(data,"dasdasdasdasdasdasdasd")
  // console.log(
  //   "selectedPlaceselectedPlaceselectedPlaceselectedPlaceselectedPlace",
  //   selectedPlace
  // );

  return (
    <>
      <BannerSection />
      {/* {product.map((data, index) => (
            <ShopCard key={data.id} data={data} index={index} limit={6} />
          ))} */}
      {console.log("pprodyuct", product)}
      {product?.length > 0 ? (
        <>
          {product?.map((data, index) => (
            <ShopCard
              key={data.id}
              data={data}
              index={index}
              limit={8}
              whereIsUsed={"home"}
            />
          ))}
        </>
      ) : (
        <p style={{ textAlign: "center" }}>No Products Found</p>
      )}
      {/* <Testimonial /> */}
      {/* <LocationModal
        showLocSuccess={showLocSuccess}
        setShowLocSuccess={setShowLocSuccess}
        handleClick={handleClick}
        isPending={isPending}
        handlePlaceSelect={handlePlaceSelect}
        handleAddress={handleAddress}
        selectedPlace={selectedPlace}
        setSelectedPlace={setSelectedPlace}
      /> */}
    </>
  );
};

export default HomeScreen;
