import React, { useEffect, useState } from "react";
import apis from "../services";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";
import { Pagination } from "../components";

const Notification = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [pageNumber, setPageNumber] = useState(1);

  let pageSize = 10;

  const {
    isLoading,
    error,
    refetch,
    data: { data: getNotify } = {},
  } = useQuery({
    queryKey: ["getNotify"],
    queryFn: () => apis.getNotify(),
    onError: (error) => {
      console.error("Error geting Order History:", error);
    },
  });
  console.log("getNotify", getNotify);
  let total = getNotify?.data?.pagination?.total;

  useEffect(() => {
    refetch();
  }, [pageNumber]);

  const handlePageChange = async (page) => {
    setPageNumber(page);
  };
  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <div className="heading-bg">
            <h2>{t("titles.notification")}</h2>
          </div>
          <div id="coupon-parent" className="coupon-page">
            {getNotify?.data.notifications.length > 0 ? (
              getNotify?.data.notifications?.map((data, index) => {
                return (
                  <div className={`coupon ${data?.is_expired}`} key={index}>
                    <div className="left">
                      <img src="/assets/images/Frame.png" alt="product" />
                    </div>
                    <div className="right">
                      <div className="one">
                        <h2>
                          {detectLang(data, "message", lang)}
                          {/* {data?.message} {t("titles.token")} */}
                        </h2>
                        <span>
                          {detectLang(data, "title", lang)}
                          {/* {data?.title} */}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="no-order">{t("messages.noNotification")} </p>
            )}
          </div>
          {getNotify?.data.notifications.length > 0 && (
            <div className="pagination-con">
              <Pagination
                currentPage={pageNumber}
                totalPages={Math.ceil(total / pageSize)}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default Notification;
