import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";

import useNavigate from "../../helper/useNavigate";
import LinkCustom from "../../helper/Link";

import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginValidationSchema } from "../../helper/ValidationSchema";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { VerifyOTPModal } from "../Modals";
import { setUser } from "../../redux/slice/userSlice";
import { useTranslation } from "react-i18next";
import detectLang from "../../helper/detectLanguage";

const Login = () => {
  let { user } = useSelector((store) => store.user);
  const [pass, setPass] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showOTPSuccess, setShowOTPSuccess] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  // LOGIN INTEGRATION
  const { mutate, isPending } = useMutation({
    mutationFn: apis.authLogin,
    onError: function ({ message }) {
      console.log("MESAAGE", message);
      toast.error(message, { id: 1 });
      if (message == "Please Verify Your Account!") {
        setShowVerifyOTP(true);
      }
    },
    onSuccess: ({ data: user, status }) => {
      // console.log("DATA-USER-Login:", user);
      if (status === 200) {
        if (user?.data?.email_verified_at) {
          dispatch(setUser(user?.data));

          toast.success(t("responses.toast.login"), { id: 1 });
          localStorage.setItem("token", user?.token);
          navigate("/");
        }
      }
    },
  });
  // LOGIN INTEGRATION END
  const handleSubmit = async (values) => {
    // console.log("VALUES:", values);
    mutate(values);
  };

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleSubmit}
        validationSchema={LoginValidationSchema}
      >
        {({ errors, touched }) => (
          <div className="login-wrapper">
            <div className="login-left">
              <div className="login-logo">
                <LinkCustom to="/">
                  <img
                    src="/assets/images/infyom.png"
                    alt="logo"
                    className="logimg"
                  />
                </LinkCustom>
              </div>
              <div className="left">
                <Form className="form-log" id="loginForm">
                  <h4>{t("titles.welcomeBack")}</h4>
                  <p>{t("descriptions.loginDesc1")}</p>
                  <label className="email-label">
                    {t("labels.email")}
                    {/* <span className="red">Phone</span> */}
                  </label>
                  <div className="input-container">
                    <Field
                      id="emailInput"
                      type="email"
                      name="email"
                      placeholder={t("placeholders.email")}
                      autoComplete="email"
                      className={
                        errors.email && touched.email ? "error-input" : ""
                      }
                    />
                    {/* <Field
                type="tel"
                id="phoneInput"
                style={{ display: "none" }}
                placeholder=" phone"
              /> */}
                    <img
                      src="/assets/images/icons/mail-icon.png"
                      className="one"
                      alt=""
                      id="email-img"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />

                    {/* <span id="email-error" className="error-message" /> */}
                  </div>

                  <label className="pass">
                    {t("labels.password")} <span className="red" />{" "}
                  </label>

                  <div className="input-container">
                    <Field
                      id="password"
                      type={pass ? "text" : "password"}
                      placeholder={t("placeholders.password")}
                      name="password"
                      required=""
                      autoComplete="current-password"
                      className={
                        errors.password && touched.password ? "error-input" : ""
                      }
                    />
                    <img
                      src="/assets/images/icons/pass-icon.png"
                      className="pass-icon"
                      alt=""
                    />
                    {pass ? (
                      <span>
                        <FaRegEye
                          className="eye"
                          onClick={() => {
                            setPass(!pass);
                          }}
                        />
                      </span>
                    ) : (
                      <span>
                        <FaRegEyeSlash
                          className="eye"
                          onClick={() => {
                            setPass(!pass);
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />

                  <div className="container-forgot">
                    {/* <div className="remember">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="checkbox-login"
                        />
                        <p>Remember me</p>
                      </div> */}
                    <div className="forgot">
                      <LinkCustom to="/forgot-password">
                        {" "}
                        <p>{t("titles.forgetPassword")}</p>
                      </LinkCustom>
                    </div>
                  </div>
                  <div className="container-social">
                    {/* <div className="one">
                    <img src="/assets/images/icons/apple.png" alt="" />
                  </div> */}
                    {/* <div onClick={() => login()}>
                      <div className="two">
                        <img src="/assets/images/icons/google.png" alt="" />
                      </div>
                    </div> */}

                    {/* <div className="three">
                    <img src="/assets/images/icons/fb.png" alt="" />
                  </div> */}
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      <button
                        disabled={isPending}
                        type="submit"
                        className="logbtn"
                        id="login-form"
                      >
                        {isPending ? t("buttons.loading") : t("buttons.login")}
                        {/* Login */}
                      </button>
                    </div>
                    <div className="col-12 my-2 dont-account">
                      <p className="p-item">
                        {t("descriptions.dontHaveAccount")}
                        <LinkCustom
                          to="/signup"
                          style={{ textDecoration: "none", fontSize: "0.9em" }}
                        >
                          {t("buttons.signup")}
                        </LinkCustom>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* <div className="right">
              <video
                width="100%"
                height="100%"
                autoPlay="autoplay"
                playsInline=""
                style={{
                  pointerEvents: "none",
                  borderRadius: "20px 0 0 20px",
                  background: "#333333",
                }}
              >
                <source
                  src="https://www.youtube.com/watch?v=tzSXjwkFnVc"
                  type="video/mp4"
                />
                Your browser does not support the video t
              </video>
            </div> */}
          </div>
        )}
      </Formik>
      <VerifyOTPModal
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
      />
      {/* <OtpSuccessModal
      showOTPSuccess={showOTPSuccess}
      setShowOTPSuccess={setShowOTPSuccess}
      showVerifyOTP={showVerifyOTP}
      setShowVerifyOTP={setShowVerifyOTP}
    /> */}
    </>
  );
};

export default Login;
