import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShopCard } from "../components";
import detectLang from "../helper/detectLanguage";
import { useTranslation } from "react-i18next";

const ViewAllProducts = () => {
  const { id } = useParams();
  const [limit, setLimit] = useState(10);
  let { product } = useSelector((product) => product.product);
  console.log("PRODUCT", product);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;


  const filteredProducts = product.filter((product) => product.id == id);

  const handleLoadMore = () => {
    setLimit((prevLimit) => prevLimit + 10);
  };
  console.log("filteredProducts", filteredProducts);
  // console.log("categoryId:", id);

  
  return (
    <div className="container-fluid" id="jtc-tokenn">
      <div className="heading-bg">
        <h2>
        {detectLang(filteredProducts[0], "name", lang)}
          {/* {filteredProducts[0]?.name} */}
          </h2>
      </div>
      <section className="product-details-page-three">
        <div className="container-fluid">
          {filteredProducts.map((data, index) => {
            return (
              <ShopCard
                key={data.id}
                data={data}
                index={index}
                limit={limit}
                whereIsUsed={"/viewall"}
                onLoadMore={handleLoadMore}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ViewAllProducts;
