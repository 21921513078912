import React from "react";

import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import CardData from "../components/ShopCard/CardData";
import { ShopCard } from "../components";
import { useTranslation } from "react-i18next";
const Favorite = ({ className = "col-lg-4 col-md-6 col-12" }) => {
  const { t } = useTranslation();

  const {
    isLoading,
    error,
    refetch,
    data: { data: getProductFavourite } = {},
  } = useQuery({
    queryKey: ["getProductFavourite"],
    queryFn: () => apis.getFavouriteProduct(),
    onError: (error) => {
      console.error("Error geting Favourite:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("getProductFavourite", getProductFavourite);

  return (
    <>
      {isLoading ? (
        <div className="loaderWrapper-cart">
          <div className="loader"></div>
        </div>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <div className="heading-bg">
            <h2>{t("titles.favourite")}</h2>
          </div>
          {/* <section className="product-details-page-three">
        <div className="container-fluid"> */}

          <section className="shop-card-con" id="sec3">
            <div className="wrapper container">
              <div className="container">
                <div className="main-heading">
                  {/* <h2>{data?.product?.name}</h2> */}
                </div>

                <div className="row card-con">
                  {getProductFavourite?.data?.favourites?.length > 0 ? (
                    getProductFavourite?.data?.favourites?.map(
                      (data, index) => {
                        return (
                          <>
                            <CardData
                              data={data}
                              key={index}
                              refetch={refetch}
                            />
                          </>
                        );
                      }
                    )
                  ) : (
                    <p className="no-order-fav">
                      {t("messages.noFavouriteProduct")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
};

export default Favorite;
