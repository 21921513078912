
import { useEffect } from "react";
import { useLocation ,useParams,useNavigate} from "react-router-dom";
import i18next from "i18next";

const useLanguageURL = () => {
  
  const { lang: _lang } = useParams();
  const lang = _lang || "sp";
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if(lang === "en" || lang=== "sp")
    {
      i18next.changeLanguage(lang);
    }
  const currentLang = localStorage.getItem("i18nextLng");

  const languageInUrl = pathname.split("/")[1];
  
  useEffect(() => {
    if (!["en", "sp"].includes(languageInUrl)) {
      const modifiedUrl = `/${currentLang}${pathname}`;
      return navigate(modifiedUrl);
    }
  }, [pathname, currentLang, languageInUrl]);
};

export default useLanguageURL;
