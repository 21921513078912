import React from 'react'
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';


import LinkCustom from "../../helper/Link";
export const ThankyouModal = ({ showTYSuccess, setShowTYSuccess }) => {
    return (
        <Modal
            show={showTYSuccess}
            onHide={() => setShowTYSuccess(false)}
            centered
            size="lg"
            className="ty-modal"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-content">
                <div className="modal-body" bis_skin_checked="1">
                    <img src="/assets/images/thankyou.png" alt="thankyou" />
                    <h3>Thank you for your order!</h3>
                    <LinkCustom to="/">GO HOME</LinkCustom>
                </div>
            </div>
        </Modal>
    )
}
