// stripe checkout popup

import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { IoIosWarning } from "react-icons/io";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import {
  CardElement,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
} from "@stripe/react-stripe-js";
import apis from "../services";
// import SuccessModal from "./Modal/SuccessModal";
// import { useNavigate } from "react-router-dom";
import useNavigate from "../helper/useNavigate";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { clearCart } from "../redux/slice/productSlice";
import { ThankyouModal } from "./Modals/ThankYou";
import { useTranslation } from "react-i18next";
// import { ThreeDots } from "react-loader-spinner";

export default function CheckoutForm({
  handlePaymentModal,
  item,
  dataProceed,
  setShowTYSuccess,
}) {
  // console.log("ITEM DATA:", item?.state?.data);
  console.log(
    "handlePaymentModalhandlePaymentModalhandlePaymentModal",
    handlePaymentModal
  );
  console.log("dataProceed", dataProceed);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  // const [showTYSuccess, setShowTYSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const cardStyle = {
    style: {
      base: {
        color: "#000",
        fontFamily: "Poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        border: "1px solid red",
        "::placeholder": {
          color: "#3e3e3e",
        },
      },
      invalid: {
        fontFamily: "Poppins, sans-serif",
        color: "#FF3333        ",
        iconColor: "#FF3333        ",
      },
    },
  };

  const { user } = useSelector((store) => store.user);

  const [clientSeceretKey, setClientSecretKey] = useState(null);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const {
    mutate,
    isPending,
    data: checkoutDataCard,
  } = useMutation({
    mutationFn: apis.cartCheckout,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: checkout, status }) => {
      console.log(
        "CART CHECKOUT",
        checkout?.order?.original?.data?.clientSecret
      );
      setClientSecretKey(checkout?.order?.original?.data?.clientSecret);
    },
  });

  //   console.log("DATA CLIENT SECRET:", data);
  // const [showModal, setShowModal] = useState(false);
  // const handleToggleModal = () => {
  //   setShowModal(!showModal);
  // };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const element = elements.getElement(CardNumberElement);
    const expElement = elements.getElement(CardExpiryElement);
    const cvcElement = elements.getElement(CardCvcElement);
    if (element?._complete) {
      let res;
      // if (dataProceed?.promo_code == "") {
      //   let { promo_code, ...rest } = dataProceed;

      //   try {
      //     res = await apis.cartCheckout(rest);
      //   } catch ({ message }) {
      //     setProcessing(false);
      //     toast.error(message);
      //   }
      // } else {
      //   try {
      //     res = await apis.cartCheckout(dataProceed);
      //   } catch ({ message }) {
      //     setProcessing(false);
      //     toast.error(message);
      //   }
      // }
      // console.log(res, "RESSSSSSSSSSSS");

      try {
        if (dataProceed)
          res = await apis.cartCheckout({ address: dataProceed });
      } catch ({ message }) {
        setProcessing(false);
        toast.error(message);
      }

      if (res?.data?.client_secret) {
        const payload = await stripe.confirmCardPayment(
          res?.data?.client_secret,
          {
            payment_method: {
              card: element,
            },
          }
        );

        if (payload) {
          console.log("payloadpayload IDDD", payload?.paymentIntent?.id);
          // const res = await apis.orderConfirm({
          //   payment_id: Number(payload?.paymentIntent?.id),
          // });
          // console.log("RES", res);

          
          toast.success(t("responses.toast.orderPlace"));
          dispatch(clearCart());
          handlePaymentModal();
          // navigate("/");
          setShowTYSuccess(true);
        }
      }
    }

    setProcessing(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="pay_form">
        <div className="mb-3">
          <label className="fw-semibold mb-2 d-block position-relative">
            <span class="text-purple position-absolute top-50 end-0 translate-middle-y fs-4 mt-2 pt-1 me-3"></span>
            <span className="card-num-head">{t("titles.cardNo")}</span>
            <CardNumberElement
              options={cardStyle}
              onChange={handleChange}
              className="pay_input "
            />
          </label>
        </div>
        <div className="d-flex mb-3">
          <div className="w-100">
            <label className="fw-semibold mb-2 d-block">
              <span className="card-num-head">
                {t("titles.expirationDate")}
              </span>
              <CardExpiryElement
                options={cardStyle}
                onChange={handleChange}
                className="pay_input"
              />
            </label>
          </div>
          <div className="ms-3 w-100">
            <label className="fw-semibold mb-2 d-block">
              <span className="card-num-head">CVC</span>
              <CardCvcElement
                options={cardStyle}
                onChange={handleChange}
                className="pay_input"
              />
            </label>
          </div>
        </div>
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
          className="btn   w-100 btn-pay d-flex justify-content-center align-items-center"
        >
          <span id="button-text">
            {processing ? t("buttons.loading") : t("buttons.payNow")}
          </span>
        </button>

        {error && (
          <div className="card-error text-danger mt-3" role="alert">
            {error}
          </div>
        )}
        {/* <ThankyouModal
          showTYSuccess={showTYSuccess}
          setShowTYSuccess={setShowTYSuccess}
        /> */}
      </form>
    </>
  );
}
