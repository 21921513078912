import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const routesWithoutFooter = [
    "/en/login",
    "/sp/login",
    "/en/signup",
    "/ap/signup",
    "/en/forgot-password",
    "/sp/forgot-password",
    "/en/confirm-password",
    "/sp/confirm-password",
  ];
  const { t } = useTranslation();

  const [shouldRenderFooter, setShouldRenderFooter] = useState(
    !routesWithoutFooter.includes(location.pathname)
  );
  useEffect(() => {
    setShouldRenderFooter(!routesWithoutFooter.includes(location.pathname));
  }, [location.pathname]);

  return (
    <React.Fragment>
      {shouldRenderFooter && (
        <footer className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 align-self-center increase">
                <div className="left">
                  <Link to="/">
                    <img
                      src="/assets/images/footer-logo.png"
                      alt=""
                      //   style={{ width: 150 }}
                    />
                  </Link>
                  {/* <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    OUR MISSION
                  </p> */}
                  <p className="para muted">{t("descriptions.footerDesc1")}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 increase">
                <div className="center">
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    {t("titles.contactUs")}
                  </p>
                  <p className="para muted">{t("descriptions.footerDesc2")}</p>
                  {/* <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    OUR MISSION
                  </p>
                  <p className="para muted">
                    Our mission is to be the leading Blockchain Technology
                    provider for the specialty coffee industry. Committed to
                    top-quality products and exceptional service, we aim to
                    create opportunities for our employees and showcase our
                    expertise. Our goal is to make a positive impact on the
                    industry by demonstrating the value of Blockchain
                    Technology, one customer at a time.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 increase">
                <div className="right">
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    {t("titles.newsLetter")}
                  </p>
                  <p className="para muted"> {t("descriptions.footerDesc3")}</p>
                  <div className="joint-input my-3">
                    <input type="email" placeholder={t('placeholders.yourEmail')} />
                    <button className="button primary-button">{t('buttons.submit')}</button>
                  </div>
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    {t("titles.followUs")}
                  </p>
                  <div className="flex-list my-2">
                    <a
                      href="https://twitter.com/javatimescaffe?t=Q2myIHKv4waNHL3Wev-Q-g&s=09"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/icons/twitter.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=13bmTsSj54I&t=4s"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/icons/youtube.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@antonioleite9"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/icons/tiktok.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </a>
                    {/* <Link
                      to="https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        src="./assets/images/icons/snapchat.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link> */}
                    <a
                      href="https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/facebook.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/instagram.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </a>
                    {/* <Link
                    to="https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
                    target="_blank"
                  >
                    <img
                      src="./assets/images/icons/instagram.png"
                      className="md-icon"
                      alt="social icon"
                    />
                  </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </React.Fragment>
  );
};

export default Footer;
