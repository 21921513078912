import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import apis from "../services";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CartCheckout } from "../helper/ValidationSchema";
import { useLocation } from "react-router-dom";

import useNavigate from "../helper/useNavigate";

import { clearCart } from "../redux/slice/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { PaymentModal } from "../components/Modals";
import { loadStripe } from "@stripe/stripe-js";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import axios from "axios";
import { setUser } from "../redux/slice/userSlice";
import { ThankyouModal } from "../components/Modals/ThankYou";
import { useTranslation } from "react-i18next";

const promise = loadStripe(
  "pk_test_51MvYFsHR4p2bAk2CwOzjbovbZZQI05WyT3OlF7Ov1jEB8s3qApeF7VK1DoZJDpXXg24IcfuEMou7gxeKtjLYpqrd00XW9orPni"
);

const Checkout = () => {
  const [formattedAddress, setFormattedAddress] = useState("");
  let { user } = useSelector((store) => store.user);
  const [isEdit, setIsEdit] = useState(false);
  const [showTYSuccess, setShowTYSuccess] = useState(false);
  const checkoutData = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClick = () => {
    console.log("CLICK");

    if (user) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude", latitude);
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk`
            )
            .then((response) => {
              console.log("RESPONSE", response);
              setFormattedAddress(response.data.results[0].formatted_address);
              dispatch(setUser({ ...user, address: formattedAddress }));
              // mutate({
              //   lat: latitude,
              //   long: longitude,
              //   address: formattedAddress,
              // });
            })
            .catch((error) => {
              console.error("Error fetching address:", error);
            });
        },
        (error) => {
          console.error("Error getting user location:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    }
  };
  const handleEditAddress = () => {
    console.log("CLICK");
    setIsEdit(true);
  };

  console.log("USERRR", user);

  const [showModal, setShowModal] = useState(false);
  const [dataProceed, setDataProceed] = useState({});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [deliverAddressErrMsg, setDeliveryAddressErrMsg] = useState("");

  const handleToggleModal = () => {
    console.log("showMODALCHECK", showModal);
    setShowModal(!showModal);
    // if(showModal){
    //   console.log("IFFFF")
    //   setShowModal(false)
    //   setShowTYSuccess(true)
    // }else{
    //   console.log("elseee")
    // }
  };
  console.log("checkoutData", checkoutData);

  const { mutate, isPending } = useMutation({
    mutationFn: apis.cartCheckout,
    onError: function ({ data, message }) {
      console.log(data, "datadatadatadata");
      toast.error(message);
    },
    onSuccess: ({ data: checkout, status }) => {
      console.log("CART CHECKOUT", checkout);
      dispatch(clearCart());
      toast.success(t('responses.toast.orderPlace'));
      // setShowModal(true);
    },
  });

  const handleProceed = async (values) => {
    // console.log("values",values)
    // mutate({address:formattedAddress});

    if (!selectedPlace && !formattedAddress) {
      // setDeliveryAddressErrMsg("Delivery Address is Required");
      // return;
    }
    setDeliveryAddressErrMsg("");
    // setDataProceed(selectedPlace.label);
    setShowModal(true);
  };
  // console.log("DATAPROCEED", dataProceed);
  console.log("selectedPlace", selectedPlace);
  const handlePlaceSelect = async (place) => {
    console.log("FUNCTION RUN handlePlaceSelect", place);
    setSelectedPlace(place);

    try {
      const results = await getLatLng(place);
      console.log("Latitude:", results.lat);
      console.log("Longitude:", results.lng);
    } catch (error) {
      console.error("Error fetching LatLng:", error);
    }
  };

  console.log("formattedAddress", formattedAddress);
  return (
    <>
      <section id="checkout">
        <div className="checkout-wrapper-heading">
          <div className="left">
            <ul>
              <li className="img-con1">
                <img src="/assets/images/order.png" alt="" />
              </li>
              <li>{t("titles.orderSummary")}</li>
            </ul>
          </div>
        </div>

        <Formik
          initialValues={{
            address: "",
            longitude: "12.345",
            latitude: "67.890",
          }}
          // onSubmit={handleSubmit}
          validationSchema={CartCheckout}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <div className="order-summary-wrapper" id="orderSummaryForm">
                {isEdit && (
                  <div className="one">
                    <label for="">{t("labels.deliveryAddress")}</label>
                    <div className="input-con">
                      <img
                        src="/assets/images/location.png"
                        alt="location"
                        className="location"
                      />
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk"
                        selectProps={{
                          selectedPlace,
                          onChange: setSelectedPlace,
                          onSelect: handlePlaceSelect,
                          placeholder: t("placeholders.address"),
                        }}
                      />
                    </div>
                    <p className="error-message">
                      {deliverAddressErrMsg && deliverAddressErrMsg}
                    </p>
                    <div className="wrapper-location-btn">
                      <button
                        onClick={() => setIsEdit(false)}
                        className="btn-use-current-loc-back"
                      >
                        {t("titles.back")}
                      </button>
                    </div>
                  </div>
                )}

                {!isEdit && (
                  <div className="one">
                    <label for="">{t("labels.deliveryAddress")}</label>
                    <div className="input-con">
                      <img
                        src="/assets/images/location.png"
                        alt="location"
                        className="location"
                      />
                      {console.log(
                        "formattedAddressformattedAddressformattedAddressformattedAddress",
                        formattedAddress
                      )}
                      <input
                        type="text"
                        name="address"
                        id=""
                        value={formattedAddress.substring(0, 60)}
                        placeholder={
                          formattedAddress
                            ? formattedAddress.substring(0, 60) // Adjust the number to your desired length
                            : t("placeholders.enterDeliveryAddress")
                        }
                        // disabled
                      />

                      {formattedAddress && (
                        <img
                          src="/assets/images/edit-checkout.png"
                          alt="editcheckout"
                          className="edit"
                          onClick={handleEditAddress}
                        />
                      )}
                    </div>
                    <p className="error-message">
                      {deliverAddressErrMsg && deliverAddressErrMsg}
                    </p>
                    <div className="wrapper-location-btn">
                      <button
                        onClick={handleClick}
                        className="btn-use-current-loc"
                      >
                        {t("buttons.useCurrentLoc")}
                      </button>
                    </div>
                    {/* <ErrorMessage
                      name="address"
                      component="div"
                      className="error-message"
                    /> */}
                  </div>
                )}

                <div className="one">
                  <label for="">{t("titles.orderSummary")}</label>
                  <div className="con-summary">
                    <div className="down">
                      <div className="summary-wrapper">
                        {/* <h2 className="fiat">Fiat</h2> */}
                        <div className="up">
                          <div className="two">
                            <p className="p1">{t("titles.orderTotal")}</p>
                            <p className="p2">
                              Mx. {checkoutData?.state?.data?.subtotal}
                            </p>
                          </div>
                          {/* <div className="two">
                            <p className="p1">Delivery Charges</p>
                            <p className="free">
                              {checkoutData?.state?.data?.delivery}
                            </p>
                          </div> */}
                          {/* {console.log("CHECK DATA:", checkoutData)} */}
                          <div className="two">
                            <p className="p1">{t("titles.tax")}</p>
                            <p className="p2">
                              Mx.{" "}
                              {Number(checkoutData?.state?.data?.tax).toFixed(
                                2
                              )}
                            </p>
                          </div>
                          {/* <div className="two">
                            <p className="p1">Delivery Charges</p>
                            <p className="p2">
                              Mx. {checkoutData?.state?.data?.delivery_charges}
                            </p>
                          </div> */}
                          <div className="two">
                            <p className="p1">{t("titles.grandTotal")}</p>
                            <p className="p2">
                              Mx. {checkoutData?.state?.data?.total}
                            </p>
                          </div>
                        </div>
                        {/* <h2 className="mt-3 fiat">JTC Token</h2>
                        <div className="up">
                          <div className="two">
                            <p className="p1">Order Total</p>
                            <p className="p2">
                              ${checkoutData?.state?.data?.tokens}
                            </p>
                          </div>
                          <div className="two">
                            <p className="p1">Delivery Charges</p>
                            <p className="free">
                              {checkoutData?.state?.data?.tokens_delivery}
                            </p>
                          </div>
                          <div className="two">
                            <p className="p1">Tax</p>
                            <p className="free">
                              {Number(
                                checkoutData?.state?.data?.tax_token
                              ).toFixed(2)}
                            </p>
                          </div>

                          <div className="two">
                            <p className="p1">Grand Total</p>
                            <p className="p2">
                              {checkoutData?.state?.data?.total_token}
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="btn-wrapper">
          <button
            className="proceed-btn"
            id=""
            onClick={() => handleProceed(dataProceed)}
            disabled={isPending}
          >
            {t("buttons.checkout")}
            {/* {isPending ? "Processing" : "PROCEED"} */}
          </button>
        </div>
      </section>
      <PaymentModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
        mutate={mutate}
        item={checkoutData}
        dataProceed={dataProceed}
        promise={promise}
        setShowTYSuccess={setShowTYSuccess}
      />
      <ThankyouModal
        showTYSuccess={showTYSuccess}
        setShowTYSuccess={setShowTYSuccess}
      />
    </>
  );
};

export default Checkout;
